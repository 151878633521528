import React from "react";
import usmanDP from "../images/usman.png";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";

const Article = (props) => {
  let postDate = new Date(props.date);

  const options = { year: "numeric", month: "short", day: "numeric" };
  const formattedDate = postDate.toLocaleDateString("en-US", options);

  const isoDate = postDate.toISOString().split("T")[0];

  const ogImageUrl = props.featuredImage || "https://via.placeholder.com/256";

  return (
    <>
      {/* Blog Article */}
      <div
        key={props.id}
        className="flex lg:flex-row md:flex-row sm:flex-col flex-col justify-between isolate lg:max-w-4xl md:max-w-4xl sm:max-w-4xl w-full mx-auto gap-8 lg:py-8 md:py-8 sm:py-8 py-8 px-4"
      >
        <div className="lg:w-64 md:w-64 sm:w-full w-full md:mx-auto sm:mx-auto flex-shrink-0 aspect-square relative">
          <img
            alt={props.title}
            src={ogImageUrl}
            className="w-full h-full object-cover rounded-2xl"
          />
          <div className="caption" />
        </div>

        <div>
          <div className="flex lg:justify-items-center md:justify-items-center gap-4">
            <time dateTime={isoDate} className="text-sm font-inter py-1">
              {formattedDate}
            </time>
            {props.categories.edges.map((category, catIndex) => (
              <a
                key={catIndex}
                href={`/${category.node.slug}`}
                className="font-medium font-inter rounded-2xl bg-blue-900 text-white text-sm px-3 py-1"
              >
                {category.node.name}
              </a>
            ))}
          </div>
          <div>
            <h3 className="text-xl font-semibold mt-5">
              <a href={props.slug}>
                <span className="font-inter" />
                {props.title}
              </a>
            </h3>
            <p
              className="font-inter text-sm mt-7"
              dangerouslySetInnerHTML={{ __html: props.excerpt }}
            ></p>
          </div>
          <div className="mt-7">
            <div className="flex justify-items-center gap-4">
              <img
                alt
                src={usmanDP}
                className="w-8 object-cover rounded-full"
              />
              <div>
                <p>
                  <a className="pt-1" href="/">
                    <span className="" />
                    {props.author}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Blog Article */}
    </>
  );
};
export default Article;
