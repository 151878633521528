import React from 'react';

const currentDate = new Date();
let currentYear = currentDate.getFullYear();

export default function Footer() {
  return (
    <>
      {/* Features */}
      <div className="bg-slate-800 px-4 py-10 sm:px-6 lg:px-8 lg:py-28 mt-10">
        {/* Grid */}
        <div className="grid gap-6 grid-cols-2 sm:gap-12 lg:grid-cols-3 lg:gap-8">
          {/* Stats */}
          <div className='text-center'>
            <h4 className="text-lg sm:text-xl font-semibold text-white dark:text-neutral-200">
              Projects Served
            </h4>
            <p className="mt-2 sm:mt-3 text-4xl sm:text-6xl font-bold text-white">
              100+
            </p>
            <p className="mt-1 text-white dark:text-neutral-500">
              From Top Companies
            </p>
          </div>
          {/* End Stats */}
          {/* Stats */}
          <div className='text-center'>
            <h4 className="text-lg sm:text-xl font-semibold text-white dark:text-neutral-200">
              Professional Hours Worked
            </h4>
            <p className="mt-2 sm:mt-3 text-4xl sm:text-6xl font-bold text-white">
              2,000+
            </p>
            <p className="mt-1 text-white dark:text-neutral-500">
              Total Number of Hours Worked
            </p>
          </div>
          {/* End Stats */}
          {/* Stats */}
          <div className='text-center'>
            <h4 className="text-lg sm:text-xl font-semibold text-white dark:text-neutral-200">
              Customer Satisfaction Ratio
            </h4>
            <p className="mt-2 sm:mt-3 text-4xl sm:text-6xl font-bold text-white">
              99%
            </p>
            <p className="mt-1 text-white dark:text-neutral-500">
              Over a decade
            </p>
          </div>
          {/* End Stats */}
        </div>
        {/* End Grid */}
      </div>
      {/* End Features */}
      <footer className="bg-slate-900 mt-auto w-full pt-2 pb-10 px-4 sm:px-6 lg:px-8 mx-0">
        {/* Grid */}
        <div className="text-center">

          {/* Social Brands */}
          <div className="mt-3 space-x-2">
            <a
              className="inline-flex justify-center items-center w-10 h-10 text-center text-gray-500 hover:bg-gray-100 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition dark:text-gray-500 dark:hover:text-gray-200 dark:hover:bg-gray-800"
              href="https://twitter.com/UsmanAliQureshi" target="_blank" rel="noopener noreferrer"
            >
              <svg
                className="w-3.5 h-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
              </svg>
            </a>
            <a
              className="inline-flex justify-center items-center w-10 h-10 text-center text-gray-500 hover:bg-gray-100 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition dark:text-gray-500 dark:hover:text-gray-200 dark:hover:bg-gray-800"
              href="https://github.com/usmanaliqureshi" target="_blank" rel="noopener noreferrer"
            >
              <svg
                className="w-3.5 h-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
              </svg>
            </a>
          </div>
          {/* End Social Brands */}

          <div>
            <a
              className="flex-none text-xl font-semibold text-white dark:text-white"
              href="/"
              aria-label="Brand"
            >
              Usman Ali Qureshi
            </a>
          </div>
          {/* End Col */}

          <div className="mt-3">
            <p className="text-white">
              Copyright &copy; Usman Ali Qureshi {currentYear}. All Rights Reserved.
            </p>
          </div>

        </div>
        {/* End Grid */}
      </footer>
    </>

  )
}