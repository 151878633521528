// Import everything needed to use the `useQuery` hook
import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Outlet } from "react-router-dom";

/**
 * CSS
 */
import './index.css';

/**
 * Components
 */
import Home from './components/home'
import Contact from './components/contact';
import Single from './components/singlearticle';
// import errorPage from './components/error';

const App = () => {
  useEffect(() => {
    import('preline');
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={
            <>
              <Outlet />
            </>
          }>
            <Route index element={
              <>
                <Home />
              </>
            }>
            </Route>
            <Route path="contact" element={
              <>
                <Contact />
              </>
            } />
          </Route>
          <Route path=":slug" element={
            <>
              <Single />
            </>
          }></Route>
          <Route path="*" element={<errorPage />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App;