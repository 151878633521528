import React  from 'react';

import { useParams } from "react-router-dom";
import { useQuery, gql } from '@apollo/client';
import InnerHTML from 'dangerously-set-html-content';
import { Helmet } from "react-helmet";
import dateFormat from "dateformat";

import Header from "./header";
import Footer from "./footer";

const Single = () => {

    const { slug } = useParams();

    const GET_POST = gql`
  query {
    post(id: "${slug}", idType: SLUG) {
        title
        status
        content
        date
        postId
        slug
        author {
            node {
              name
            }
          }
        seo {
            metaDesc
            metaKeywords
            canonical
            title
            twitterTitle
            twitterDescription
            opengraphImage {
                mediaItemUrl
              }
          }
    }
  }
`;

    const { loading, error, data } = useQuery(GET_POST);

    if (loading) return (
        <>
            <div class="flex items-center justify-center h-screen">
                <div class="relative">
                    <div class="h-8 w-8 rounded-full border-t-8 border-b-8 border-gray-200"></div>
                    <div class="absolute top-0 left-0 h-8 w-8 rounded-full border-t-8 border-b-8 border-blue-500 animate-spin">
                    </div>
                </div>
            </div>
        </>
    );

    if (error) return <p>Error : {error.message}</p>;

    let postDate = new Date(data.post.date);

    const content = data.post.content;

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{data.post.seo.title}</title>
                <link rel="canonical" href={data.post.seo.canonical} />
                <meta name="description" content={data.post.seo.metaDesc} />
                <meta property="og:title" content={data.post.seo.title} />
                <meta property="og:description" content={data.post.seo.metaDesc} />
                <meta property="og:image" content={data.post.seo.opengraphImage.mediaItemUrl} />
                <meta property="og:url" content={data.post.seo.canonical} />
                <meta name="author" content={data.post.author.node.name} />
                <meta name="twitter:title" content={data.post.seo.title} />
                <meta name="twitter:description" content={data.post.seo.metaDesc} />
                <meta name="twitter:url" content={data.post.seo.opengraphImage.mediaItemUrl} />
                <meta name="twitter:card" content="summary" />

            </Helmet>
            <Header />
            {/* Blog Article */}
            <div key={data.post.id} className="article single-post max-w-3xl px-4 pt-6 lg:pt-10 sm:px-6 lg:px-8 mx-auto">
                <div className="max-w-2xl">
                    {/* Avatar Media */}
                    <div className="flex justify-between items-center mb-6">
                        <div className="flex w-full sm:items-center gap-x-5 sm:gap-x-3">
                            <div className="flex-shrink-0">
                                <img
                                    className="h-12 w-12 rounded-full"
                                    src="https://secure.gravatar.com/avatar/6ac9e2060a5456c3746f67789520c2fe?s=192&d=mm&r=g"
                                    alt="Usman Ali Qureshi"
                                />
                            </div>
                            <div className="grow">
                                <div className="grid sm:flex sm:justify-between sm:items-center gap-2">
                                    <div>
                                        <div className="sm:mb-1 block text-left cursor-pointer">
                                            <span className="font-semibold text-gray-800 dark:text-gray-200">
                                                Usman Ali Qureshi
                                            </span>
                                        </div>
                                        <ul className="text-xs text-gray-500">
                                            <li className="inline-block relative pr-6 last:pr-0 last-of-type:before:hidden before:absolute before:top-1/2 before:right-2 before:-translate-y-1/2 before:w-1 before:h-1 before:bg-gray-300 before:rounded-full dark:text-gray-400 dark:before:bg-gray-600">
                                                {dateFormat(postDate, "dS mmmm, yyyy")}
                                            </li>
                                            <li className="inline-block relative pr-6 last:pr-0 last-of-type:before:hidden before:absolute before:top-1/2 before:right-2 before:-translate-y-1/2 before:w-1 before:h-1 before:bg-gray-300 before:rounded-full dark:text-gray-400 dark:before:bg-gray-600">
                                                8 min read
                                            </li>
                                        </ul>
                                    </div>
                                    {/* Button Group */}
                                    {/* <div>
                                            <button
                                                type="button"
                                                className="py-1.5 px-2.5 sm:py-2 sm:px-3 inline-flex justify-center items-center gap-x-1.5 sm:gap-x-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-xs sm:text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                                            >
                                                Category
                                            </button>
                                        </div> */}
                                    {/* End Button Group */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Avatar Media */}
                    {/* Content */}
                    <div className="space-y-5 md:space-y-8">
                        <div className="space-y-3">
                            <h1 className="text-2xl font-bold md:text-3xl dark:text-white">
                                {data.post.title}
                            </h1>
                            <InnerHTML className="text-lg text-gray-800 dark:text-gray-200" html={content} />
                        </div>
                    </div>
                    {/* End Content */}
                </div>
            </div>
            {/* End Blog Article */}
            <Footer />
        </>
    )
}

export default Single;