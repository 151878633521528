// Import everything needed to use the `useQuery` hook
import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Helmet } from 'react-helmet';

/**
 * Components
 */
import Header from './header';
import Footer from './footer';
import Article from './article'

export default function Home() {
  const GET_POSTS = gql`
  query {
    posts {
      edges {
        node {
          link
          postId
          slug
          title
          date
          excerpt
          categories {
            edges {
              node {
                id
                name
                slug
              }
            }
          }
          seo {
            metaDesc
            metaKeywords
            canonical
            title
            twitterTitle
            twitterDescription
            opengraphImage {
              mediaItemUrl
            }
          }
          author {
          node {
            name
          }
        }
        }
      }
    }
  }
`;
  const { loading, error, data } = useQuery(GET_POSTS);

  if (loading) return (
    <>
    <div className='absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 '>
      <svg className="pl" width={240} height={240} viewBox="0 0 240 240">
        <circle className="pl__ring pl__ring--a" cx={120} cy={120} r={105} fill="none" stroke="#000" strokeWidth={20} strokeDasharray="0 660" strokeDashoffset={-330} strokeLinecap="round" />
        <circle className="pl__ring pl__ring--b" cx={120} cy={120} r={35} fill="none" stroke="#000" strokeWidth={20} strokeDasharray="0 220" strokeDashoffset={-110} strokeLinecap="round" />
        <circle className="pl__ring pl__ring--c" cx={85} cy={120} r={70} fill="none" stroke="#000" strokeWidth={20} strokeDasharray="0 440" strokeLinecap="round" />
        <circle className="pl__ring pl__ring--d" cx={155} cy={120} r={70} fill="none" stroke="#000" strokeWidth={20} strokeDasharray="0 440" strokeLinecap="round" />
      </svg>
    </div>
    </>
  );

  if (error) return <p>Error : {error.message}</p>;

  return (
    <>
      <Header />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Usman Ali Qureshi - Full Stack Developer</title>
        <link rel="canonical" href="https://usmanaliqureshi.com" />
        <meta name="description" content="Personal Blog" />
        <meta property="og:title" content="Usman Ali Qureshi - Full Stack Developer" />
        <meta property="og:description" content="Personal Blog" />
        <meta property="og:image" content="../images/usman.png" />
        <meta property="og:url" content="https://usmanaliqureshi.com" />
        <meta name="author" content="Usman Ali Qureshi" />
        <meta name="twitter:title" content="Usman Ali Qureshi - Full Stack Developer" />
        <meta name="twitter:description" content="Personal Blog" />
        <meta name="twitter:url" content="https://usmanaliqureshi.com" />
        <meta name="twitter:card" content="summary" />
    </Helmet>

      <div className="container-fluid pt-14 xl:pt-[4.5rem] lg:pt-[4.5rem] md:pt-[4.5rem] pb-[4.5rem] xl:pb-24 lg:pb-24 md:pb-24 bg-blue-200 usman-bg mb-8">
        <div className="max-w-7xl flex flex-wrap mx-[-15px] mt-[-50px] xl:mt-0 lg:mt-0 mx-auto items-center">
          <div
            className="md:w-8/12 lg:w-5/12 xl:w-5/12 w-full flex-[0_0_auto] px-[15px] mt-[50px] xl:mt-0 lg:mt-0 max-w-full flex !relative !mx-auto"
            data-cues="slideInDown"
            data-group="header"
            data-disabled="true"
          >
            <div
              className="img-mask mask-1"
              data-cue="slideInDown"
              data-group="header"
              data-show="true"
              style={{
                animationName: "slideInDown",
                animationDuration: "700ms",
                animationTimingFunction: "ease",
                animationDelay: "0ms",
                animationDirection: "normal",
                animationFillMode: "both"
              }}
            >
              <img
                className='rounded-full'
                src={require('../images/usman.png')}
                alt="Usman Ali Qureshi"
              />
            </div>
            <div
              className="card !shadow-[0_0.25rem_1.75rem_rgba(30,34,40,0.07)] !absolute"
              style={{
                bottom: "10%",
                right: "2%",
                animationName: "slideInDown",
                animationDuration: "700ms",
                animationTimingFunction: "ease",
                animationDelay: "300ms",
                animationDirection: "normal",
                animationFillMode: "both"
              }}
              data-cue="slideInDown"
              data-group="header"
              data-show="true"
            >
              <div className="card-body py-4 px-5 bg-white rounded-lg">
                <div className="flex flex-row items-center">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 409.6 409.6"
                      data-inject-url="https://sandbox-tailwindcss.ibthemespro.com/assets/img/icons/lineal/check.svg"
                      className="svg-inject icon-svg !w-[2.2rem] !h-[2.2rem] text-[#d16b86] text-pink !mx-auto !mr-3"
                    >
                      <path
                        className="lineal-stroke"
                        d="M204.8 409.6C91.9 409.6 0 317.7 0 204.8c0-49.9 18.2-98.1 51.2-135.5 4.4-5 12-5.5 17-1.1s5.5 12 1.1 17c-29.1 33-45.2 75.5-45.2 119.5 0 99.6 81.1 180.7 180.7 180.7s180.7-81.1 180.7-180.7S304.4 24.1 204.8 24.1c-27.3-.1-54.2 6.1-78.7 18-6 2.9-13.2.4-16.1-5.6-2.9-6-.4-13.2 5.6-16.1C143.4 6.9 173.9-.1 204.8 0c112.9 0 204.8 91.9 204.8 204.8s-91.9 204.8-204.8 204.8z"
                      />
                      <path
                        className="lineal-fill"
                        d="M349.4 204.8c0 79.8-64.7 144.6-144.6 144.6S60.2 284.6 60.2 204.8 125 60.2 204.8 60.2 349.4 125 349.4 204.8z"
                      />
                      <path
                        className="lineal-stroke"
                        d="M204.8 361.4c-86.4 0-156.6-70.2-156.6-156.6S118.4 48.2 204.8 48.2s156.6 70.2 156.6 156.6-70.2 156.6-156.6 156.6zm0-289.1c-73.1 0-132.5 59.4-132.5 132.5s59.4 132.5 132.5 132.5 132.5-59.5 132.5-132.5S277.9 72.3 204.8 72.3z"
                      />
                      <path
                        className="lineal-stroke"
                        d="M200.9 246.7c-8.8 0-17.2-3.5-23.5-9.7L145 204.5c-4.7-4.7-4.7-12.3 0-17s12.3-4.7 17 0l32.5 32.5c3.6 3.5 9.3 3.5 12.8 0l49.8-49.9c4.7-4.7 12.3-4.7 17 0s4.7 12.3 0 17L224.4 237c-6.2 6.2-14.7 9.7-23.5 9.7z"
                      />
                    </svg>
                  </div>
                  <div>
                    <h3
                      className="counter  !mb-0 whitespace-nowrap"
                      style={{ visibility: "visible" }}
                    >
                      10+
                    </h3>
                    <p className="text-[0.7rem] leading-normal  !mb-0 whitespace-nowrap">
                      Years of Experience
                    </p>
                  </div>
                </div>
              </div>
              {/*/.card-body */}
            </div>
            {/*/.card */}
          </div>
          {/*/column */}
          <div
            className="lg:w-6/12 lg:!ml-[8.33333333%] xl:w-6/12 xl:!ml-[8.33333333%] xxl:w-5/12 flex-[0_0_auto] px-[15px] mt-[50px] xl:mt-0 lg:mt-0 max-w-full text-center lg:text-left xl:text-left"
            data-cues="slideInDown"
            data-group="page-title"
            data-delay={600}
            data-disabled="true"
          >
            <h1
              className="font-bold text-blue-900 font-bold leading-[1.2] text-4xl sm:text-5xl md:text-6xl lg:text-6xl mb-5"
              data-cue="slideInDown"
              data-group="page-title"
              data-delay={600}
              data-show="true"
              style={{
                animationName: "slideInDown",
                animationDuration: "700ms",
                animationTimingFunction: "ease",
                animationDelay: "600ms",
                animationDirection: "normal",
                animationFillMode: "both"
              }}
            >
              Usman Ali Qureshi
            </h1>
            <p
              className="text-blue-900 lead text-[1.25rem] !leading-[1.5] mb-7 md:!px-10 lg:!px-0"
              data-cue="slideInDown"
              data-group="page-title"
              data-delay={600}
              data-show="true"
              style={{
                animationName: "slideInDown",
                animationDuration: "700ms",
                animationTimingFunction: "ease",
                animationDelay: "900ms",
                animationDirection: "normal",
                animationFillMode: "both"
              }}
            >
              Hi, I'm Usman! 👋 A passionate coder, food lover, and traveler, I create engaging digital experiences while balancing life as a proud father of a beloved daughter.
            </p>
            <div
              className="flex justify-center lg:!justify-start xl:!justify-start"
              data-cues="slideInDown"
              data-group="page-title-buttons"
              data-delay={900}
              data-cue="slideInDown"
              data-disabled="true"
              data-show="true"
              style={{
                animationName: "slideInDown",
                animationDuration: "700ms",
                animationTimingFunction: "ease",
                animationDelay: "900ms",
                animationDirection: "normal",
                animationFillMode: "both"
              }}
            >
            </div>
          </div>
          {/*/column */}
        </div>
        {/* /.row */}
      </div>


      {data.posts.edges.map(
        (post, key) => (
          <>
            <Article 
            id={post.node.postID} 
            title={post.node.title} 
            categories={post.node.categories} 
            excerpt={post.node.excerpt} 
            slug={post.node.slug} 
            date={post.node.date} 
            author={post.node.author.node.name}
            featuredImage={post.node.seo.opengraphImage?.mediaItemUrl} />
          </>
        )
      )}
      <Footer />
    </>
  )
}